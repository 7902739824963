.taskbar{
  position: absolute;
  width: 100vw;
  height: 39px;
  background: rgba(254, 254, 254, 0.8);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  bottom: 0;
  z-index: 10000;
}

.taskcont{
  position: relative;
  width: 100%;
  height: 100%;
}

.taskright{
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  height: 100%;
  margin-left: 10px;
  display: flex;
}

.taskIcon{
  width: 24px;

  svg{
    color: #303030;
  }
}

.taskDate{
  width: 44px;
  height: 100%;
  display: flex;
  padding: 6px 8px 6px 6px;
  font-size: 0.64em;
  flex-direction: column;

  div{
    width: 100%;
    text-align: center;
    font-weight: 400;
  }
}

.tasksCont{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &[data-side="center"]{
    align-items: center;
  }

  &[data-side="left"]{
    align-items: flex-start;
  }
}

.tsbar{
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  transition: all ease-in-out 200ms;
}

.tsIcon{
  position: relative;
  width: 33px;
  height: 33px;
  margin: auto 3px;
  box-sizing: border-box;
  border-radius: 0;
  background: rgba(254, 254, 254, 0);
  transform-origin: center;
  animation: popintro 800ms ease-in-out;

  &:hover{
    background: rgba(#fefefe, 0.64);
    border-radius: 4px;
    transition: all ease-in-out 200ms;
  }

  &[data-open='true']:after{
    content: "";
    position: absolute;
    display: block;
    bottom: 0;
    width: 6px;
    height: 3px;
    border-radius: 4px;
    background: #858585;
  }

  &[data-active='true']:after{
    width: 12px;
    background: #1f4dcd;
  }
}

.graybd{
  border: solid 1px #c3c3c3;
  border-width: 0 0 0 2px;
}

@keyframes popintro {
  0%{
    transform: scale(0);
  }
  40%{
    transform: scale(1.125);
  }
  70%{
    transform: scale(0.725);
  }
  100%{
    transform: scale(1);
  }
}
